<template>
  <v-form>
    <v-row>
      <v-col
        cols="5"
      >
        <v-text-field
          v-model="form.contractName"
          :label="$vuetify.lang.t('$vuetify.contract.contractName')"
          clearable
        />
      </v-col>
      <v-col
        cols="5"
      >
        <v-text-field
          v-model="form.operator"
          :label="$vuetify.lang.t('$vuetify.contract.operator')"
          clearable
        />
      </v-col>
      <v-col
        cols="2"
      >
        <v-btn
          class="mx-2"
          color="primary"
          fab
          small
          @click="handleSearch"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

  export default {
    data() {
      return {
        form: {
          contractName: null,
          operator: null
        }
      }
    },
    computed: {
    },
    created () {
    },
    methods: {
      initialize () {
      },
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
